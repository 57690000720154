<template>
	<div class="main-content">
		<page-header title="Waitlist" pre-title="Overview">
		</page-header>

		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<div class="col pl-0">
								<div class="input-group input-group-flush d-flex">
									<v-datepicker range-separator=" to " v-model="filter.dateRange" @change="fetchWaitListSummary(true)"
										placholder="Filter by date" range></v-datepicker>
								</div>
							</div>
							<div class="col-auto d-flex align-items-end px-0">
								<download-button v-if="tableData.length" :downloading="downloadingReport" label="Download Report"
									@download="downloadReport()">
								</download-button>
							</div>
						</div>
						<div class="col px-0 d-flex" v-if="tableData.length">
							<div class="col"></div>
							<div class="col-auto">
								<filter-button :options="sortOrders" :loading="false">
									<template #label>
										<span class="text-grey">Sort:</span>
										<span class="text-capitalize">{{ filter.sort }}</span>
									</template>

									<template #option="data">
										<span class="w-100 d-inline-block" @click="selectSortOrder(data.value)">
											{{ data.value }}
										</span>
									</template>
								</filter-button>
							</div>
						</div>
						<b-table responsive striped hover selectable :items="tableData" :fields="fields" :busy="loading"
							empty-text="No records available" @row-clicked="viewWaitlistDetail" show-empty>
							<template #table-busy>
								<div class="d-flex flex-column justify-content-center align-items-center">
									<div class="spinner-border" role="status"></div>
									<p class="text-center mt-3"><strong>Loading...</strong></p>
								</div>
							</template>
							<template #empty="data">
								<p class="text-center">{{ data.emptyText }}</p>
							</template>
							<template #cell(date)="data">
								<span>{{ data.item.date | date('MMMM d, yyyy') }}</span>
							</template>

							<template #cell(count)="data">
								<span>{{ data.item.waitlistCount }}</span>
							</template>

							<template #cell(routes)="data">
								<span>{{ data.item.routeCount }}</span>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
								</div>
								<div class="col-auto">
									<b-pagination @change="(e) => fetchWaitListSummary(false, e)" v-model="currentPage"
										:total-rows="totalRecords" :per-page="pageSize"></b-pagination>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader'
import TableView from '@/mixins/tableview'
import moment from 'moment'
import DownloadButton from '@/components/core/DownloadButton'
import FilterButton from '@/components/core/FilterButton'; import { ExportToCsv } from 'export-to-csv'
import { extractErrorMessage } from '@/utils/helpers'
export default {
  name: 'WaitListSummary',
  components: { DownloadButton, FilterButton, PageHeader },
  mixins: [TableView],
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      tableData: [],
      filter: {
        dateRange: null,
        sort: ''
      },
      fields: [
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'count',
          label: 'Waitlist Count'
        },
        {
          key: 'routes',
          label: 'Number of Routes'
        }
      ],
      downloadingReport: false,
      sortOrders: ['Descending', 'Ascending']
    }
  },
  created() {
    // this.setDefaultFilterDate();
    this.fetchWaitListSummary()
  },
  computed: {
    dateRange() {
      return this.filter.dateRange
        ? this.filter.dateRange.map((date) => moment(date).format('YYYY-MM-DD'))
        : null
    },
    dateFilterValid() {
      return (
        this.filter.dateRange?.length &&
        this.filter.dateRange.filter((date) => !!date).length > 0
      )
    }
  },
  methods: {
    setDefaultFilterDate() {
      this.filter.dateRange =
        [moment().startOf('month').toDate(), moment().endOf('month').toDate()]
    },
    async fetchWaitListSummary(refreshUI = false, event) {
      if (refreshUI) {
        this.currentPage = 1
      }
      this.loading = true
      try {
        const startDate = this.dateRange?.length ? this.dateRange[0] : ''
        const endDate = this.dateRange?.length ? this.dateRange[1] : ''
        const dateParams = this.dateFilterValid && (startDate && endDate) ? { fromdate: startDate, todate: endDate } : {}
        const response = await this.axios.get('/waitlist/getlist', {
          params: {
            page: event,
            size: this.pageSize,
            sort: this.filter.sort,
            ...dateParams
          }
        })
        this.tableData = response.data.data
        this.totalRecords = response.data.metadata.count
        this.currentPage = response.data.metadata.currentPage
      } catch (e) {
        
      } finally {
        this.loading = false
      }
    },
    async downloadReport() {
      this.downloadingReport = true
      try {
        const waitlistData = await this.axios.get('/waitlist/getlist').then((res) => res.data?.data || [])
        const csvData = waitlistData.map((x) => {
          return {
            date: x.date,
            waitlistCount: x.waitlistCount,
            routeCount: x.routeCount
          }
        })
        const docName = 'Waitlist Report'
        const csvParams = {
          filename: docName,
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true,
          showTitle: true,
          title: docName,
          useTextFile: false,
          useBom: true,
          headers: [
            'Date',
            'Waitlist count',
            'Routes'
          ]
        }
        const csvExporter = new ExportToCsv(csvParams)
        csvExporter.generateCsv(csvData)
        this.$swal({
          icon: 'success',
          title: 'Report downloaded',
          text: 'Report has been downloaded successfully',
          showCloseButton: true
        })
      } catch (e) {
        (e)
        this.$swal({
          icon: 'error',
          title: 'An error occurred!',
          text: extractErrorMessage(e, 'Failed to download report'),
          showCloseButton: true
        })
      } finally {
        this.downloadingReport = false
      }
    },
    viewWaitlistDetail(waitlist) {
      this.$router.push({ name: 'WaitListDetail', query: { date: waitlist.date } })
    },
    selectSortOrder(data) {
      if (this.filter.sort !== data) {
        this.filter.sort = data.toLowerCase()
        this.fetchWaitListSummary()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/b-table";
</style>
